.Wrapper {
  height: calc(100vh - 70px);
  width: calc(100vw - 350px);
}

.Context {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.EmptyTextContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
