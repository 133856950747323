.Container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.LeftPart {
  height: calc(100% - 32px);
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
}

.RightPart {
  height: calc(100% - 32px);
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
}

.DefaultCard {
  width: 80%;
  background-color: #ffffff;
}

.DefaultCard p, .DefaultCard span {
  color: #224563;
}

.HighlightCard {
  width: 80%;
  background-color: #4C8CC2;
}

.HighlightCard p, .HighlightCard span {
  color: white;
}

.Recurrence {
  font-size: large;
  margin-bottom: 0;
}

.Amount {
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 0;
}

.Description {
  font-size: normal;
  margin-bottom: 0;
}

.FeatureCheckStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.FeatureCheckStyle span {
  display: inline-block;
}

.FeatureCheckStyle p {
  display: inline-block;
  margin: 0;
}

.ButtonStyle {
  width: 80%;
}
