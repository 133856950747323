.Container {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.LeftPart {
  background-color: #FFFFFF;
  height: calc(100vh - 70px);
  width: 350px;
}

.RightPart {
  height: calc(100vh - 70px);
  width: calc(100vw - 350px);
}
