.LoadingContainer {
  width: 100%;
  height: 100%;
  text-align: center;
}

.Header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.LeftHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}
