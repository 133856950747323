.Wrapper {
  height: 32px;
  width: 248px;
  padding-right: 16px;
  position: absolute;
  background: white;
  z-index: 2;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SelectWrapper {
  width: 128px;
}

.Icon:hover {
  cursor: pointer;
}