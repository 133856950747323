.ModalContainer {
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}

.ModalStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 0;
  left: 0;
  padding: 16px;
  height: 100%;
  background-color: white;
}

.DecorationItem {
  display: none;
}

.Logo {
  width: auto;
  height: 64px;
}

@media print {
  .ControlItem {
    display: none;
  }
  .DecorationItem {
    display: flex;
    justify-content: space-between;
  }
}
