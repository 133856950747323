.Wrapper {
  background-color: #FFFFFF;
  color: #4C8CC2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
  z-index: 2;
}

.LeftPart {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.LeftText {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.Text {
  font-size: large;
  color: #2D5D85;
}

.Arrow {
  font-size: small;
  margin: 0px 5px 0px 5px;
}

.Seperator {
  width: 16px;
}