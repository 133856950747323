.SectionTitle {
  margin-top: 32px;
  font-weight: bold;
  font-size: medium;
}

.DefaultOrder {}

.SelectedOrder {
  border-left-color: #87D068;
  border-left-style: solid;
  border-left-width: 4px;
  padding-left: 16px;
}
