.EstimationWrapper p {
  text-align: left;
  font-size: medium;
  margin-top: 32px;
}

.ButtonWrapper {
  width: 100%;
  margin-bottom: 16px;
}
