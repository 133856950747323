.Container {
  height: 100%;
  width: 100%;
  padding: 32px;
}

.StepsWrapper {
  margin-bottom: 32px;
}

.MainWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MainWrapper p {
  color: #2D5D85;
  font-size: large;
  text-align: center;
}