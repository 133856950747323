.ModalContainer {
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  padding: 0 calc((100% - 794px) / 2 - 16px);
}

.ModalStyle {
  display: flex;
  flex-direction: column;
  width: 794px;
  top: 0;
  left: 0;
  /* padding: 16px; */
  height: 100%;
  background-color: white;
}

.ModalContent {
  padding: 16px;
}

.Header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 2px #4C8CC2 solid;
}

.Title {
  margin-top: auto;
  margin-bottom: auto;
  font-size: xx-large;
  font-weight: bold;
}

.Logo {
  width: auto;
  height: 124px;
}

.Studio {
  margin-top: 16px;
}

.ClientInfo {
  text-align: right;
}

.Orders, .Extras, .CGV, .PriceTag, .Signature {
  margin-top: 32px;
}

.PriceTag {
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
  align-items: flex-end;
  justify-content: center;
}

.Signature {
  display: flex;
  width: calc(100% - 64px);
  align-items: center;
  justify-content: flex-end;
}

.PriceTag div {
  margin-right: 32px;
}

.PaiementDetail {
  text-align: right;
}

.PaiementDetail p {
  margin-right: 96px;
}

.Signature div {
  margin-right: 64px;
}

.DateTemplate {
  display: flex;
  width: 64px;
  justify-content: space-around;
}