.ButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ButtonContainerAbsolute {
  position: absolute;
  top: 20px;
  right: 20px;
}

.IdentityContainer {
  width: 100%;
}