.LoadingContainer {
  width: 100%;
  height: 100%;
  text-align: center;
}

.EmptyContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
