.Example p {
  text-align: left;
  font-size: medium;
}

.ButtonWrapper {
  width: 100%;
  margin-bottom: 16px;
}

.ExampleImage {
  width: 100%;
}
