.ImageContainer {
  display: flex;
}

.BigImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  position: relative;
  top: 0;
  left: 0;
}

.Image {
  max-width: 256px;
  object-fit: contain;
  margin: 8px;
}

.BigImage {
  max-width: calc(100% - 16px);
  object-fit: contain;
  margin: 8px;

  position: relative;
  top: 0;
  left: 0;
}

.LikedIcon {
  position: relative;
  color: black;
  margin: 0px;
  margin-left: auto;
  margin-right: 24px;
  font-size: 24px;
}

.ImageSelected {
  max-width: 256px;
  object-fit: contain;
  margin: 8px;
  border-color: #87D068;
  border-style: solid;
  border-width: 4px;
}

.BigImageSelected {
  max-width: calc(100% - 16px);
  object-fit: contain;
  margin: 8px;
  border-color: #87D068;
  border-style: solid;
  border-width: 4px;
}

.Image:hover, .ImageSelected:hover, .BigImage:hover, .BigImageSelected:hover {
  cursor: pointer;
}
