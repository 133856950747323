.Image {
  max-width: 256px;
  height: 100%;
  margin: 8px;
}

.ImageSelected {
  max-width: 256px;
  height: 100%;
  margin: 8px;
  border-color: #87D068;
  border-style: solid;
  border-width: 4px;
}

.Image:hover, .ImageSelected:hover {
  cursor: pointer;
}

.SectionTitle {
  margin-top: 32px;
  font-weight: bold;
  font-size: medium;
}
