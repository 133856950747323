.Container {
  height: 100%;
  margin: 32px;
}

.PhotoWrapper {
  width: 100%;
  margin: 32px 0px;
}

.ButtonWrapper {
  width: 100%;
  margin-bottom: 32px;
}
