.Wrapper {
  height: 100%;
  width: 100%;
  padding: 32px 32px 0px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.SectionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 32px;
}

.Row {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  height: 96px;
}

.LeftPart {
  width: calc(50% - 5px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.RightPart {
  width: calc(50% - 5px);
  margin-left: 5px;
  display: flex;
  justify-content: center;
}

.Image:hover {
  cursor: pointer;
  transform: scale(1.1);
}
