.ToolbarWrapper {
  /* width: calc((16px * 2) + (24px * 5) + (16px * 5) + 16px); */
  width: calc((16px * 2) + (24px * 3) + (16px * 3) + 16px);
  position: absolute;
  top: calc(70px + 32px);
  /* left: calc(100% - 248px - 32px); */
  left: calc(100% - 168px - 32px);
  background-color: #F9FAFB;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  z-index: 2;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
}

.Icon {
  height: 24px;
  width: 24px;
  background-color: #F3F4F6;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4C8CC2;
}

.Icon:hover {
  cursor: pointer;
  background-color: #91B8D950;
  animation: to-hover-on 0.5s;
}

.IconSelected {
  height: 24px;
  width: 24px;
  background-color: #4C8CC2;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}

.IconSelected:hover {
  cursor: pointer;
  background-color: #91B8D9;
  animation: to-hover-0ff 0.5s;
}

@keyframes to-hover-on {
  from {
    background-color: #F3F4F6;
  }
  to {
    background-color: #91B8D950;
  }
}

@keyframes to-hover-off {
  from {
    background-color: #4C8CC2;
  }
  to {
    background-color: #91B8D950;
  }
}