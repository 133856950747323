.MainRow {
  margin-bottom: 64px;
}

.CenteredCol {
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
}

.ClickableText {
  color: #224563;
}

.ClickableText:hover {
  cursor: pointer;
}
