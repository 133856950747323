.Wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  color: #224563;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
}

.TabWrapper {
  height: 100%;
  width: 80px;
  display: flex;
  flex-direction: column;
  background-color: #F9FAFB;
}

.ContentWrapper {
  height: 100%;
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}

.Tab {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Tab:hover {
  cursor: pointer;
  background-color: #B3CEE550;
  animation: to-hover 0.5s;
}

@keyframes to-hover {
  from {
    background-color: #F9FAFB;
  }
  to {
    background-color: #B3CEE550;
  }
}

.ActiveTab {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  animation: to-active 0.2s;
}

@keyframes to-active {
  from {
    background-color: #B3CEE550;
  }
  to {
    background-color: #FFFFFF;
  }
}

.ActiveTab:hover {
  cursor: pointer;
}

.Icon {
  font-size: 150%;
}
