.Text {
	text-align: center;
}

.Title {
	font-size: 8vh;
	/* font-weight: bold; */
	color: #fff;
	margin-top: 1em;
	font-style: italic;
}

.Name {
	font-size: 10vh;
	font-style: normal;
	font-weight: bold;
	margin-left: 2vh;
}

.SubTitle {
	color: #fff;
	font-size: 4vh;
	font-style: italic;
	margin: 0px 320px;
}