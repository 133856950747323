.Center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Option {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: large;
}

.OptionValue {
  margin-left: 10px;
  font-weight: bold;
}
