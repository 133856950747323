.PriceTagWrapper {
  width: calc((16px * 2) + 160px);
  position: absolute;
  top: calc(70px + 32px + 40px + 16px);
  left: calc(100% - 192px - 32px);
  background-color: #F9FAFB;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  z-index: 2;
  border-radius: 8px;
  padding: 16px;
}
