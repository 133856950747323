.Container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.LeftArea {
  width: 50%;
  height: 100%;
  margin-right: 50%;
  position: absolute;
  z-index: 1;
}

.RightArea {
  width: 50%;
  height: 100%;
  margin-left: 50%;
  position: absolute;
  z-index: 1;
}

.FullscreenImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.PlayButton {
  top: 32px;
  right: 128px;
  font-size: 32px;
  position: absolute;
  color: white;
  z-index: 2;
}

.LikeButton {
  top: 32px;
  right: 64px;
  font-size: 32px;
  position: absolute;
  color: white;
  z-index: 2;
}

.PlayButton:hover, .LikeButton:hover {
  cursor: pointer;
}
