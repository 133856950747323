.Container {
  height: 100%;
}

.StepsStyle {
  height: 80%;
  margin-bottom: 32px;
  max-width: calc(100vw - 64px);
}

.ButtonWrapper {
  width: 100%;
}