.Image {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.Image:hover {
  cursor: pointer;
}

.ProductImageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProductImage {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.CarrouselContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
