.Container {
  height: 100%;
}

.ImageWrapper {
  margin: 32px 0px;
}

.MainText {
  font-size: large;
  margin-bottom: 0;
}

.ButtonWrapper {
  margin-top: 32px;
}