.Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 16px;
}

.ImageWrapper {
  width: 16vw;
  display: block;
  margin-top: 16px;
  overflow-wrap: break-word;
}

.Image {
  width: 96%;
  height: auto;
}

@media print {
  .Image, .ImageWrapper {
    break-inside: avoid;
    break-before: always;
    break-after: always;
  }
}