.Wrapper {
  height: 100%;
  width: 100%;
  padding: 32px 32px 0px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SectionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 32px;
}

.ImageWrapper {
  height: 100%;
  width: 100%;
  margin-bottom: 0px;
  padding-bottom: 0px;
  overflow-y: scroll;
}

.Image {
  width: 100%;
  margin-bottom: 10px;
}

.Image:hover {
  cursor: pointer;
  transform: scale(1.1);
}
